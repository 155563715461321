import React from 'react';
// const exp = {
//   1: [
//     'Internship IT Support',
//     'Fasilkom Universitas Indonesia',
//     'September - December 2015'
//   ],
//   2: [
//     'Internship IT Support',
//     'PT Airindo Sakti',
//     'July - October 2018'
//   ],
//   3: [
//     'FrontEnd Developer',
//     'PT Falah Inovasi Teknologi',
//     'Januari 2021 - Present'
//   ]
// }

const exp = [
  {
    title: 'Internship IT Support',
    office: 'Fasilkom Universitas Indonesia',
    date: 'September - December 2015'
  },
  {
    title: 'Internship IT Support',
    office: 'PT Airindo Sakti',
    date: 'July - October 2018'
  },
  {
    title: 'FrontEnd Developer',
    office: 'PT Falah Inovasi Teknologi',
    date: 'Januari 2021 - Present'
  }
]

class Experience extends React.Component {
  render() {
    return(
      <section id={this.props.title.toLowerCase()}>
        <h1 className='section-title'>{this.props.title}</h1>
        {
          exp.reverse().map((val, key)=> {
            return (
              <article key={key} className='card'>
                <h1>{val.title}</h1>
                <h3>{val.office}</h3>
                <span>{val.date}</span>
              </article>
            )
          })
        }
      </section>
    )
  }
}

export default Experience;
