import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

class Footer extends React.Component{
  constructor(){
    super();
    this.state = {
      footer_contact: {
        'github': 'github.com/asharifauzan',
        'blogger': 'fauzanashariblog.blogspot.com',
        'linkedin-in': 'linkedin.com/in/fauzan-hibatullah-ashari-a80091153'
      }
    }
  }
  render(){
    return (
      <React.Fragment>
        <span className='copyright'>
          Copyright &copy; {new Date().getFullYear()} <b>AshariFauzan</b> and Image By
          <p>
            <a href="https://www.vecteezy.com/free-vector/dynamic" target='_blank' rel="noreferrer">Dynamic Vectors by Vecteezy</a>
          </p>
        </span>
        <div className='footer-contact'>
          {Object.entries(this.state.footer_contact).map(([key, val])=>{
            return <a href={'https://'+val} key={key} target='_blank' rel="noreferrer">
                      <FontAwesomeIcon icon={['fab', key]}/>
                  </a>
          })}
        </div>
      </React.Fragment>
    )
  }
}

export default Footer;
