import React from 'react';
import liff from './assets/img/asharifauzan-cafetaria.png';
import react_todolist from './assets/img/react-todolist.png';

const projects = [
  {
    title: 'Backend Elearning REST server API',
    desc: 'REST Server API elearning using Codeigniter, CI Rest Server and JWT.',
    img: null,
    repo: 'https://github.com/asharifauzan/elearning',
    url: 'https://asharifauzan.rf.gd'
  },
  {
    title: 'LINE LIFF Cafetaria',
    desc: `Cafetaria app to order foods & beverages integrated with LINE account & messages API
using HTML, CSS/SASS, Javascript and LINE Front-End Framework (LIFF).`,
    img: liff,
    repo: 'https://github.com/asharifauzan/asharifauzan-cafetaria',
    url: 'https://liff.line.me/1655319676-3O2PgWmq'
  },
  {
    title: 'ReactJs Todolist',
    desc: `Todolist with ReactJs & SASS (Frontendmentor Challenges) with dark mode toggle.`,
    img: react_todolist,
    repo: 'https://github.com/asharifauzan/react-todolist',
    url: 'https://asharifauzan.github.io/react-todolist'
  }
]
class Projects extends React.Component {
  render() {
    return(
      <section id={this.props.title.toLowerCase()}>
        <h1 className='section-title'>{this.props.title}</h1>
          <div className='wrapper'>
            {
              projects.map((value, key)=>{
                return(
                    <article key={key} className='card projects'>
                      <figure>
                        <img src={value['img'] ? value['img'] : 'https://bitsofco.de/content/images/2018/12/broken-1.png'} alt={value['title']}/>
                        <h2>{value['title']}</h2>
                        <figcaption>{value['desc']}</figcaption>
                      </figure>
                      <div className='link'>
                        <a rel="noreferrer" href={value['repo']}>Link Github</a>
                        <a rel="noreferrer" href={value['url']}>Website</a>
                      </div>
                    </article>
                )
              })
            }
          </div>
      </section>
    )
  }
}

export default Projects;
