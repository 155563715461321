import './Component/assets/style/style.css';
import React from 'react';
import './Component/FontAwesomeIcon';
import Navbar from './Component/Navbar';
import Section from './Component/Section';
import Footer from './Component/Footer';


function App() {
  const menu = {
    about:'About',
    experience:'Experience',
    education:'Education',
    skills:'Skills',
    projects:'Projects'
  };

  return (
    <React.Fragment>
      <header>
        <Navbar data={menu} title='AshariFauzan'/>
      </header>
      <main>
        <Section title={menu}/>
      </main>
      <footer>
        <Footer/>
      </footer>
    </React.Fragment>
  );
}

export default App;
